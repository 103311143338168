import React from "react";
import hljs from "highlight.js";
import "highlight.js/styles/github.css"; // Choose the style you prefer
import "../styles/Chats.css";
import Loading from "./Loading";

const CodeBlock = ({ code }) => {
  const [highlightedCode, setHighlightedCode] = React.useState("");

  React.useEffect(() => {
    setHighlightedCode(hljs.highlightAuto(code).value);
  }, [code]);

  return (
    <pre className="inline-flex items-center py-1 px-3 rounded my-1 bg-slate-50 overflow-x-auto">
      <code
        className="whitespace-pre-wrap"
        dangerouslySetInnerHTML={{ __html: highlightedCode }}
      />
    </pre>
  );
};

const formatContent = (text) => {
  const regex = /```[\s\S]*?```/g;
  const parts = text.split(regex);
  const codeBlocks = text.match(regex);

  return parts.map((part, index) => (
    <React.Fragment key={index}>
      {part.split("\n").map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          <span>{line}</span>
          {lineIndex < part.split("\n").length - 1 && <br />}
        </React.Fragment>
      ))}
      {codeBlocks && codeBlocks[index] && (
        <CodeBlock code={codeBlocks[index].slice(3, -3).trim()} />
      )}
    </React.Fragment>
  ));
};

const Chats = ({ chatHistory, loading }) => {
  return (
    <div className="w-full max-w-screen-md mx-auto p-4 flex flex-col">
      {chatHistory.map((chat, index) => (
        <div
          key={index}
          className={`inline-flex items-center py-1 px-3 rounded my-2 ${
            chat.role === "user"
              ? "bg-blue-200 text-black ml-auto text-base"
              : "bg-gray-200 text-black mr-auto chap-gpt-text text-lg"
          }`}
        >
          <span>{formatContent(chat.content)}</span>
        </div>
      ))}
      {loading && (
        <div className="my-2 p-4 rounded-lg bg-gray-200 mr-auto max-w-md">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Chats;
