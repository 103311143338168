import React, { useState } from "react";
import { API } from "aws-amplify";
import Chats from "./components/Chats";
import ChatInput from "./components/ChatInput";
import logo from "./assets/images/logo.png";
import "./styles/Chats.css";

function App() {
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAsk = async (prompt) => {
    setLoading(true);

    try {
      const data = await API.post("chapgptapi", "/chat", {
        body: { prompt, chatHistory },
      });

      setChatHistory([
        ...chatHistory,
        { role: "user", content: prompt },
        { role: "assistant", content: data.answer },
      ]);
      setErrorMessage(""); // Clear any previous error messages
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setErrorMessage(
          "Sorry old chum, we've spent all our ChatGPT tokens for the day. Please try again tomorrow."
        );
      } else {
        alert("Error: Could not fetch answer");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4 text-black">ChapGPT</h1>
        <h2 className="text-s mb-4 text-black">
          Because AskGPTeeves didn't sound quite right
        </h2>
        <img src={logo} alt="No riff-raff" className="mb-4 mx-auto"></img>
        <ChatInput
          onAsk={handleAsk}
          chatHistory={chatHistory}
          setChatHistory={setChatHistory}
        />
        <div className="mt-6">
          {errorMessage && (
            <p className="text-red-500 mb-4 chap-gpt-text">{errorMessage}</p>
          )}
          <h2 className="text-xl font-bold mb-2 text-white">Chat History:</h2>
          <Chats chatHistory={chatHistory} loading={loading} />
        </div>
      </div>
    </div>
  );
}

export default App;
