import React from "react";
import "../styles/Loading.css";

const Loading = () => (
  <div className="loading-dots">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default Loading;
